<script>
    export let backgroundColor = "";

	export let rightTitle = "";
	export let rightContext= "";
    export let rightTextColor = "white";
    export let rightBackground = "";
    export let rightImage = "";

	export let leftTitle = "";
	export let leftContext= "";
    export let leftTextColor = "black";
    export let leftBackground = "";
    export let leftImage = "";
</script>

<div id="parent" style="display:flex; height:100%; border: 5px solid #666; border-radius: 5px">
	<div id="right" style="flex:10; display:flex; flex-flow:column">
		<div id="red" class="center" style="color: {rightTextColor}; background-color:{backgroundColor}; flex:1; background-image: url('{rightBackground}'); background-size: cover;">
			<h2>{rightTitle}</h2>
            <p>{rightContext}</p>
            <img src='{rightImage}' alt="">
		</div>

	</div>
	<div id="left" style="flex:10; display:flex; flex-flow:column">
		<div id="red" class="center" style="color: {leftTextColor}; background-color:{backgroundColor}; flex:1; background-image: url('{leftBackground}'); background-size: cover;">
			<h2>{leftTitle}</h2>
            <p>{leftContext}</p>
            <img src='{leftImage}' alt="">
		</div>

	</div>
		
</div>
<style>
	.center {
		text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
	}

    img {
        max-width:100%;
        max-height:100%;
        border: 5px solid white; 
        border-radius: 24px;
    }

    p, h2 {
        font-weight: 900;
        font-family: sans-serif;
    }
</style>
