<script>
    import ContactMe from './components/ContactMe/ContactMe.svelte'
    import Section from './components/SectionBody/Section.svelte'

    import Content from './components/PopupView/Content.svelte'
    import Modal from './components/PopupView/Modal.svelte'

    import Youtube from './components/Youtube/Youtube.svelte'
</script>


<body>
<div class="wrapper">
    <header>
        <div class="bottom">
            <h1 class="title">Pose Coach</h1>
            <div class="download">
                <a href="https://apps.apple.com/us/app/pose-coach/id1589037753?itsct=apps_box_badge&amp;itscg=30200" style="display: inline-block; overflow: hidden; border-radius: 8px; width: 150; height: 49px;"><img src="/icons/appstore.svg" alt="Download on the App Store" style="border-radius: 8px; width: 150px; height: 49px;"></a>
                <a href="https://testflight.apple.com/join/8obWFf50" style="display: inline-block; overflow: hidden; border-radius: 8px; width: 150px; height: 49px;"><img src="/icons/testflight.svg" alt="Join on the TestFlight Beta" style="border-radius: 8px; width: 150px; height: 49px;"></a>
            </div>
            <br>
            <div class="empty">
                
                <div>
                </div>

                <div class="yt">
                    <Youtube videoId="Lpz3CiAmsNo"/>
                </div>
            </div>
            <br>
        </div>
    </header>
    <br>
    <body style="margin: 0px;">
        <Section
            backgroundColor={"#3A583D"}

            rightBackground={"/images/Section_Camera.svg"}
            rightImage={"images/import.gif"}

            leftTitle={"影片導入"}
            leftContext={"將您裝置內的影片導入 PoseCoach，依照日期幫您分類"}
            leftTextColor={"white"}
            leftBackground={"/images/Section_Camera.svg"}
        />
        <br>
        <Section
            backgroundColor={"#3A583D"}

            rightTitle={"播放器控制"}
            rightContext={"快進/退 1 秒，播放速率調整"}
            rightTextColor={"white"}
            rightBackground={"/images/Header.svg"}

            leftBackground={"/images/Header.svg"}
            leftImage={"/images/player_control.gif"}
        />
        <br>
        <Section
            backgroundColor={"#3A583D"}

            rightBackground={"/images/Section_Camera.svg"}
            rightImage={"images/AppStore5.5.png"}

            leftTitle={"三種模式的播放器回放"}
            leftContext={"內置播放器提供 純影片/ 影像+骨架 /純骨架模式，同時能調整播放速度來檢視您的肢體動作"}
            leftTextColor={"white"}
            leftBackground={"/images/Section_Camera.svg"}
        />
        <br>
        <Section
            backgroundColor={"#3A583D"}

            rightTitle={"高畫質與慢動作相機"}
            rightContext={"依照使用情境選擇適合的相機吧"}
            rightTextColor={"white"}
            rightBackground={"/images/Header.svg"}

            leftBackground={"/images/Header.svg"}
            leftImage={"/images/camera_selection.gif"}
        />
		<br>
        <div class="btn">
          	<Modal>
       			 <Content/>
  		    </Modal> 
			
        </div>
        <br>
    </body>
    <footer class="footer"> 
        <div class="likecoin-embed likecoin-button" data-liker-id="deirenwu" data-href="https://docs.like.co/developer/likecoin-button/"></div>
        <script src="https://static.like.co/sdk/v1/button.js"></script>
        <div>
         <ContactMe></ContactMe>
        </div>
    </footer>
</div>
</body>


<style>
	:global(body) {
		background-color: white;
		transition: background-color 0.3s
	}

    .title {
        font-size: 52px;
        color: white;
        padding: 28px;
    }

    .bottom {
        position: relative;
        bottom: 0;
    }

    header {
        background-size: 100% auto;
		background-image: url('/images/AppStore.png');
        background-color: #3A583D;
        background-repeat: no-repeat;
        height: 600px;
        width: 100%;
        display: flex; 
        flex-direction: column; 
        margin: 0px;
    }

    .wrapper {
        flex-grow: 1; 
        min-height: 100%;
        min-width: 100%;
    }

	.yt {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: clear;
		margin: auto;
        border: 12px solid gray;
        border-radius: 12px;
	}

	.btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: clear;
		margin: auto;
        border: 12px solid white;
        border-radius: 12px;
	}

    .download {
        background-color: clear;
        display: flex;
        flex-wrap: warp;
        justify-content: center;
        align-items: center;
        gap: 20px 12px;
    }

    .footer {
        background-color: #3A583D;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px solid #666; 
        border-radius: 5px;
    }

    .empty{
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
