<script>
  import { getContext } from 'svelte';
	
  import PopupLong from './PopupLong.svelte';

  const { open } = getContext('simple-modal');
	
	let opening = false;
	let opened = false;
	let closing = false;
	let closed = false;
 
	const showPopupLong = () => {
		open(PopupLong);
	};
	
</script>

<section>
    <div class="wrap">
	    <button class="button" on:click={showPopupLong}>About<br>Pose Coach</button>
    </div>

	<div id="state">
		{#if opening}
			<p>opening modal...</p>
		{:else if opened}
			<p>opened modal!</p>
		{:else if closing}
			<p>closing modal...</p>
		{:else if closed}
			<p>closed modal!</p>
		{/if}
	</div>
</section>

<style>
	section {
		padding-top: 0.5em;
	}
	
	#state {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0.33;
		font-size: 0.8em;
	}

    .wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button {
      min-width: 300px;
      min-height: 60px;
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      font-weight: 700;
      color: white;
      background: #3A583D;
      background: linear-gradient(90deg, #3A583D 0%, #999 100%);
      border: none;
      border-radius: 1200px;
      box-shadow: 12px 12px 24px #999;
      transition: all 0.4s ease-in-out 0s;
      cursor: pointer;
      outline: none;
      position: relative;
      padding: 10px;
    }

    button::before {
    content: '';
      border-radius: 1000px;
      min-width: calc(300px + 12px);
      min-height: calc(60px + 12px);
      border: 6px solid #3A583D;
      box-shadow: 0 0 60px rgba(0,255,203,.64);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all .3s ease-in-But 0s;
      background:  0 50% no-repeat;                
      background-size: 4.5em 4.5em;                
    }

    .button:hover, .button:focus {
      color: black;
      transform: translateY(-6px);
    }

    button:hover::before, button:focus::before {
      opacity: 1;
    }

    button::after {
      content: '';
      width: 30px; height: 30px;
      border-radius: 100%;
      border: 6px solid #3A583D;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 1.5s infinite;
    }

    button:hover::after, button:focus::after {
      animation: none;
      display: none;
    }

    @keyframes ring {
      0% {
        width: 30px;
        height: 30px;
        opacity: 1;
      }
      100% {
        width: 300px;
        height: 300px;
        opacity: 0;
      }
    }
</style>
