<script>
</script>


<div style="overflow-y: scroll; height:400px;">
    <h1>App 需求</h1>
        <li>iOS 15+</li>
    <h1>App 操作</h1>
    <div class="config">
        <h2>菜單</h2>
    </div>
        <li>打開側邊選單</li>
    <div class="video">
        <h2>影片導入</h2>
    </div>
        <li>可導入手機內影片觀賞。</li>
    <div class="camera">
        <h2>相機</h2>
    </div>
        <li>可打開您的相機開始捕捉人體關節。</li>
        <p class="text-warning">
            注意: 距離過遠或人數過多，透過網子(障礙物)使用都有可能影響關節捕捉的運算。
        </p>
    <div class="info">
        <h2>關於</h2>
    </div>
        <li> 版本 </li>
        <p> 顯示現行版本，以及過去版本列表與更新內容</p>
        <br>
        <li> 🎲 評價 Pose Coach </li> 
        <p> 在App Store 給與 Pose Coach 評價 </p> 
        <br>
        <li> 📨 電子郵件聯絡 </li>
        <p> 利用電子郵件與開發者聯繫 </p>
        <br>
        <li> ⛩  前往官方網站 </li>
        <p> 打開官方網站 <p>
        <br>
        <li> 🕷 GitHub Issue </li>
        <p> 前往 GitHub 留下 issue <p>
    <h1>關於 App 開發</h1>
    <button class="me"><a href="/about" alt=''>關於開發者</a></button>
    <br>
    <article>
        <p>現代運動越來越重視數據，任何能夠將運動可視化的資訊都可能是讓運動員更加進步的要因。</p>
        <p>但對一般運動愛好者來說能夠精準取得運動數據的工具依然不是那麼容易取得，作為一名棒球運動的愛好者，能夠仔細觀察自己在運動時關節的移動，也可以藉由將影片交給專業教練進行分析指導達到精進自己運動表現我認為也是會非常有幫助的。</p>
        <p>希望這樣一個免費且在手機上就可以使用的 App 能夠幫助到更多對運動有興趣的人，一起讓自己的運動表現能更上一層樓，其他更有趣的功能也慢慢在開發當中，如果有任何想法也歡迎與我聯絡。另外，如果您願意利用下方小額捐贈來給我一點小小的支持也將會是對我最大的鼓勵！</p>
    </article>
    <button class="donate"><a href="https://p.ecpay.com.tw/E1A5169" alt=''>給開發者<br>一點鼓勵</a></button>
    <center><a href="https://p.ecpay.com.tw/E1A5169"><img src="https://payment.ecpay.com.tw/Upload/QRCode/202112/QRCode_5b6dfba8-bacd-4b2e-a45d-28ab6c3939cf.png" alt=""></a></center>
</div>

<style>
    
    h1 {
        font-size: 2rem;
        text-align: center;
    }
       
    h2 {
		font-size: 1.25rem;
	}

    li {
        padding-left: 36px;
        padding-bottom: 2px;
    }

    p {
        width:90%;
        font-size:15px;
        text-indent : 2em;
        margin: 2px;
    }

    .text-warning {
        font-size: 12px;
        padding-left: 36px;
        color: red;
    }

	.config, .video, .camera, .info {
		padding: 0 0 0 1.5em;
		background:  0 50% no-repeat;
		background-size: 1em 1em;
		margin: 0 0 0.5em 0;
		line-height: 1.5;
        background-color: #3A583D;
        color: white;
	}

	.config {
		background-image: url('icons/line.3.horizontal.decrease@2x.png');
	}
	.video {
		background-image: url('icons/video.circle@2x.png');
	}
	.camera {
		background-image: url('icons/camera.viewfinder@2x.png');
	}
	.info {
		background-image: url('icons/info.circle.fill@2x.png');
	}

    .me {
        color: black;
		font-size: 22px;
        font-weight: bold;
		width: 250px;
		height: 83px;
        border: 2px solid #666; 
        border-radius: 13px;
		background:  0 50% no-repeat;
		background-size: 4em 5em;
		line-height: 1.5;
		background-image: url('icons/me.svg');
        padding: 0px 0px 0px 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
		margin: auto;
    }

    .donate{
        color: black;
		font-size: 24px;
        font-weight: bold;
		width: 250px;
		height: 83px;
        border: 2px solid #666; 
        border-radius: 13px;
		background:  0 50% no-repeat;
		background-size: 4em 5em;
		line-height: 1.5;
		background-image: url('icons/donate.svg');
        padding: 0px 0px 0px 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
		margin: auto;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, .6);
    }
</style>
