<script context="module">
			
			// from youtube iframe api sample code
			// https://developers.google.com/youtube/iframe_api_reference?hl=zh-TW
      var tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	
      window.onYouTubeIframeAPIReady = function() {
				window.dispatchEvent(new Event("onYouTubeIframeAPIReady"))
      }
</script>

<script>
	let divId ="player_"+parseInt(Math.random() *100000).toString()
	export let videoId;
	window.addEventListener("onYouTubeIframeAPIReady", function(){
		console.log('load')
		new YT.Player(divId, {
          height: '320',
          width: '640',
          videoId: videoId,
          playerVars: {
            'playsinline': 1
          },
          events: {
//             'onReady': onPlayerReady,
//             'onStateChange': onPlayerStateChange
          }
        })
	})
</script>
<div id={divId}>
	
</div>
