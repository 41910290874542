<div class="info">
    <h3> 聯絡我們 </h3>
    <p class="user">Pose Coach</p>
    <p class="email">derendeveloper@gmail.com</p>
    <p class="website">https://pose-coach.com</p>
<!--    <a class="line" href="https://lin.ee/ubGBO2F"> 點擊加入官方帳號好友 </a> -->
</div>


<style>
    .info {
        color: white;
        justify-content: space-between;
        text-align: left;
        margin: 20px;
    }

	.email, .website, .user {
		padding: 0 0 0 1.5em;
		background:  0 50% no-repeat;
		background-size: 1em 1em;
		margin: 0 0 0.5em 0;
		line-height: 1.5;
	}

	.user {
		background-image: url('/icons/user.svg');
	}
	.website{
		background-image: url('/icons/website.svg');
	}
	.email {
		background-image: url('/icons/email.svg');
	}
</style>
